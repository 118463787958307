// constants for all batter canvases
// the center of the strike zone, on the canvas x-axis
const SZ_MID_X = 117;
// the typical conversion ratio between inches and pixels
const PIXELS_PER_INCH = 70 / 17;

// CF + HH
// the edge of the strike zone on the canvas x-axis, nearest the first-base side
const PLATE_1B_EDGE_X = 82;
// the edge of the strike zone on the canvas x-axis, nearest the third-base side
const PLATE_3B_EDGE_X = 152;
// the plate, on the canvas y-axis
const PLATE_Y = 250;
// the top of the strike zone, on the canvas y-axis
const SZ_EDGE_TOP = 88;
// the bottom of the strike zone, on the canvas y-axis
const SZ_EDGE_BOTTOM = 172;
// the edge of the "v2" outer buffer above the top of the strike zone, on the canvas y-axis
const SZ_TOP_BUFFER_EDGE_V2 = 83.91;
// the edge of the "v2" inner buffer below the top of the strike zone, on the canvas y-axis
const SZ_TOP_BUFFER_INNER_V2 = 92.09;
// the edge of the "v2" outer buffer below the bottom of the strike zone, on the canvas y-axis
const SZ_BOTTOM_BUFFER_EDGE_V2 = 176.09;
// the edge of the "v2" inner buffer above the bottom of the strike zone, on the canvas y-axis
const SZ_BOTTOM_BUFFER_INNER_V2 = 167.91;

// CF only
// the edge of the "v1" buffer nearest the first-base batter's box, on the canvas x-axis
const SZ_1B_BUFFER_EDGE = 73.76;
// the edge of the "v1" buffer nearest the third-base batter's box, on the canvas x-axis
const SZ_3B_BUFFER_EDGE = 160.24;
// the edge of the "v2" outer buffer nearest the first-base batter's box, on the canvas x-axis
const SZ_1B_BUFFER_EDGE_V2 = 78.91;
// the edge of the "v2" inner buffer nearest the first-base batter's box, on the canvas x-axis
const SZ_1B_BUFFER_INNER_V2 = 85.09;
// the edge of the "v2" outer buffer nearest the third-base batter's box, on the canvas x-axis
const SZ_3B_BUFFER_EDGE_V2 = 155.09;
// the edge of the "v2" inner buffer nearest the third-base batter's box, on the canvas x-axis
const SZ_3B_BUFFER_INNER_V2 = 148.91;

// HF only
// the start and end of the path of the ball from the high-first cartoon angle, on the canvas x-axis
const HF_COL_VALUES = {
  start: 180.82,
  end: 57.29
};

// HH only
// the back tip of home plate, on the canvas y-axis
const PLATE_BACK_Y = 102;
// the midpoint of home plate, on the canvas y-axis
const PLATE_MID_Y = 137;
// the back tip of the "v1" inner buffer, on the canvas y-axis
const PLATE_MID_INNER_Y = 93.76;
// the front of home plate, on the canvas y-axis
const PLATE_FRONT_Y = 172;
// the edge of the "v2" outer buffer closer to first base, parallel to the back tip of home plate, on the canvas y-axis
const SZ_1B_BUFFER_BACK_V2 = 113.91;
// the edge of the "v2" outer buffer closer to third base, parallel to the back tip of home plate, on the canvas y-axis
const SZ_3B_BUFFER_BACK_V2 = 120.09;
// the back tip of the "v2" inner buffer, on the canvas y-axis
const SZ_INNER_BACK_V2 = 105.09;
// the start and end of the path of the ball from the high-home cartoon angle, on the canvas x-axis
const HH_ROW_VALUES = {
  start: 200.82,
  end: 77.29
};

// ball radius in pixels
const PITCH_CIRCLE_RADIUS = 5.97;

// shapes
// the plate, from the centerfield and high-first angles
const PLATE_CF = [
  [PLATE_Y, PLATE_1B_EDGE_X],
  [PLATE_Y, PLATE_3B_EDGE_X]
];
// the "v2" outer buffer, from the centerfield angle
const PLATE_CF_BUFFER_OUTER = [
  [SZ_TOP_BUFFER_EDGE_V2, SZ_1B_BUFFER_EDGE_V2],
  [SZ_TOP_BUFFER_EDGE_V2, SZ_3B_BUFFER_EDGE_V2],
  [SZ_BOTTOM_BUFFER_EDGE_V2, SZ_3B_BUFFER_EDGE_V2],
  [SZ_BOTTOM_BUFFER_EDGE_V2, SZ_1B_BUFFER_EDGE_V2]
];
// the "v2" inner buffer, from the centerfield angle
const PLATE_CF_BUFFER_INNER = [
  [SZ_TOP_BUFFER_INNER_V2, SZ_1B_BUFFER_INNER_V2],
  [SZ_TOP_BUFFER_INNER_V2, SZ_3B_BUFFER_INNER_V2],
  [SZ_BOTTOM_BUFFER_INNER_V2, SZ_3B_BUFFER_INNER_V2],
  [SZ_BOTTOM_BUFFER_INNER_V2, SZ_1B_BUFFER_INNER_V2]
];
// the bottom portion of the "v2" inner buffer, from the high-first angle
const PLATE_HF_BUFFER_BOTTOM_INNER = [
  [SZ_BOTTOM_BUFFER_INNER_V2, PLATE_1B_EDGE_X],
  [SZ_BOTTOM_BUFFER_INNER_V2, PLATE_3B_EDGE_X],
  [SZ_EDGE_BOTTOM, PLATE_3B_EDGE_X],
  [SZ_EDGE_BOTTOM, PLATE_1B_EDGE_X]
];
// the bottom portion of the "v2" outer buffer, from the high-first angle
const PLATE_HF_BUFFER_BOTTOM_OUTER = [
  [SZ_BOTTOM_BUFFER_EDGE_V2, PLATE_1B_EDGE_X],
  [SZ_BOTTOM_BUFFER_EDGE_V2, PLATE_3B_EDGE_X],
  [SZ_EDGE_BOTTOM, PLATE_3B_EDGE_X],
  [SZ_EDGE_BOTTOM, PLATE_1B_EDGE_X]
];
// the top portion of the "v2" inner buffer, from the high-first angle
const PLATE_HF_BUFFER_TOP_INNER = [
  [SZ_TOP_BUFFER_INNER_V2, PLATE_1B_EDGE_X],
  [SZ_TOP_BUFFER_INNER_V2, PLATE_3B_EDGE_X],
  [SZ_EDGE_TOP, PLATE_3B_EDGE_X],
  [SZ_EDGE_TOP, PLATE_1B_EDGE_X]
];
// the top portion of the "v2" outer buffer, from the high-first angle
const PLATE_HF_BUFFER_TOP_OUTER = [
  [SZ_TOP_BUFFER_EDGE_V2, PLATE_1B_EDGE_X],
  [SZ_TOP_BUFFER_EDGE_V2, PLATE_3B_EDGE_X],
  [SZ_EDGE_TOP, PLATE_3B_EDGE_X],
  [SZ_EDGE_TOP, PLATE_1B_EDGE_X]
];
// the plate, from the high-home angle
const PLATE_HH = [
  [PLATE_FRONT_Y - 0.5, PLATE_1B_EDGE_X + 0.5],
  [PLATE_MID_Y, PLATE_1B_EDGE_X + 0.5],
  [PLATE_BACK_Y + 0.5, SZ_MID_X],
  [PLATE_MID_Y, PLATE_3B_EDGE_X - 0.5],
  [PLATE_FRONT_Y - 0.5, PLATE_3B_EDGE_X - 0.5]
];
// the "v1" buffer outline, from the high-home angle
const PLATE_HH_BUFFER = [
  [PLATE_FRONT_Y, PLATE_1B_EDGE_X],
  [PLATE_FRONT_Y, SZ_1B_BUFFER_EDGE],
  [PLATE_MID_Y, SZ_1B_BUFFER_EDGE],
  [PLATE_MID_INNER_Y, SZ_MID_X],
  [PLATE_MID_Y, SZ_3B_BUFFER_EDGE],
  [PLATE_FRONT_Y, SZ_3B_BUFFER_EDGE],
  [PLATE_FRONT_Y, PLATE_3B_EDGE_X]
];
// the "v2" outer buffer, from the high-home angle
const PLATE_HH_BUFFER_OUTER = [
  [PLATE_FRONT_Y, PLATE_1B_EDGE_X],
  [PLATE_FRONT_Y, SZ_1B_BUFFER_EDGE_V2],
  [PLATE_MID_Y, SZ_1B_BUFFER_EDGE_V2],
  [PLATE_BACK_Y, SZ_1B_BUFFER_BACK_V2],
  [PLATE_BACK_Y, SZ_3B_BUFFER_BACK_V2],
  [PLATE_MID_Y, SZ_3B_BUFFER_EDGE_V2],
  [PLATE_FRONT_Y, SZ_3B_BUFFER_EDGE_V2],
  [PLATE_FRONT_Y, PLATE_3B_EDGE_X]
];
// the common render border for "v2" outer and inner buffers, from the high-home angle
// this is separate from "PLATE_HH" because of some rendering behavior - looks cleaner this way
const PLATE_HH_BUFFER_MID = [
  [PLATE_FRONT_Y - 1, PLATE_1B_EDGE_X + 1],
  [PLATE_MID_Y, PLATE_1B_EDGE_X + 1],
  [PLATE_BACK_Y + 1, SZ_MID_X],
  [PLATE_MID_Y, PLATE_3B_EDGE_X - 1],
  [PLATE_FRONT_Y - 1, PLATE_3B_EDGE_X - 1]
];
// the "v2" inner buffer, from the high-home angle
const PLATE_HH_BUFFER_INNER = [
  [PLATE_FRONT_Y - 1, SZ_1B_BUFFER_INNER_V2],
  [PLATE_MID_Y, SZ_1B_BUFFER_INNER_V2],
  [SZ_INNER_BACK_V2, SZ_MID_X],
  [PLATE_MID_Y, SZ_3B_BUFFER_INNER_V2],
  [PLATE_FRONT_Y - 1, SZ_3B_BUFFER_INNER_V2]
];
// the strike zone box, from the centerfield and high-first angles
const STRIKE_ZONE_BOX = [
  [SZ_EDGE_TOP, PLATE_1B_EDGE_X],
  [SZ_EDGE_TOP, PLATE_3B_EDGE_X],
  [SZ_EDGE_BOTTOM, PLATE_3B_EDGE_X],
  [SZ_EDGE_BOTTOM, PLATE_1B_EDGE_X]
];
// the first-base "v1" side buffer
const STRIKE_ZONE_1B_BUFFER = [
  [SZ_EDGE_TOP, PLATE_1B_EDGE_X],
  [SZ_EDGE_TOP, SZ_1B_BUFFER_EDGE],
  [SZ_EDGE_BOTTOM, SZ_1B_BUFFER_EDGE],
  [SZ_EDGE_BOTTOM, PLATE_1B_EDGE_X]
];
// the third-base "v1" side buffer
const STRIKE_ZONE_3B_BUFFER = [
  [SZ_EDGE_TOP, PLATE_3B_EDGE_X],
  [SZ_EDGE_TOP, SZ_3B_BUFFER_EDGE],
  [SZ_EDGE_BOTTOM, SZ_3B_BUFFER_EDGE],
  [SZ_EDGE_BOTTOM, PLATE_3B_EDGE_X]
];

export const GameViewerBatterCanvasConstants = {
  // constants for all batter canvases
  SZ_MID_X,
  PIXELS_PER_INCH,

  // CF + HH
  PLATE_1B_EDGE_X,
  PLATE_3B_EDGE_X,

  // CF only
  SZ_EDGE_TOP,
  SZ_EDGE_BOTTOM,
  PLATE_Y,

  // HF only
  HF_COL_VALUES,

  // HH only
  HH_ROW_VALUES,
  PLATE_FRONT_Y,

  // ball radius in pixels
  PITCH_CIRCLE_RADIUS,

  // shapes
  PLATE_CF,
  PLATE_CF_BUFFER_OUTER,
  PLATE_CF_BUFFER_INNER,
  PLATE_HF_BUFFER_BOTTOM_INNER,
  PLATE_HF_BUFFER_BOTTOM_OUTER,
  PLATE_HF_BUFFER_TOP_INNER,
  PLATE_HF_BUFFER_TOP_OUTER,
  PLATE_HH,
  PLATE_HH_BUFFER,
  PLATE_HH_BUFFER_OUTER,
  PLATE_HH_BUFFER_MID,
  PLATE_HH_BUFFER_INNER,
  STRIKE_ZONE_BOX,
  STRIKE_ZONE_1B_BUFFER,
  STRIKE_ZONE_3B_BUFFER
};
