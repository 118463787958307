import { observable } from "mobx";

export const CalibrationStatus = {
  IDLE: "IDLE",
  GENERATING: "GENERATING",
  SAVING: "SAVING"
};

export const auditStoreDefaults = {
  isAdmin: false,
  controls: false,
  calledFilter: false,
  frameInfo: null,
  gameDisplayName: "",
  gameDisplayTime: "",
  gamePk: "",
  strikeZoneEnabled: true,
  enableZoom: true,
  isVideoZoomed: true,
  videoCurrentTime: 0,
  videoDuration: 0,
  videoFrameCallbackIds: [],
  videoZoomTop: 150,
  videoZoomLeft: 0,
  pitches: [],
  players: {},
  rowBoundaries: [0, 0],
  showBoundaries: {
    top: false,
    bottom: false
  },
  ghostRow: null,
  selectedPitch: {},
  cameraCalibration: {},
  rMSEColor: "black",
  oobPitches: [],
  oobPitchFilter: false,
  problemPitchFilter: {
    broadcastVideo: false,
    centerfieldVideo: false,
    missingCalibration: false,
    opticalTracking: false,
    pitchcastVideo: false,
    reducedConfidence: false,
    untrackedBip: false,
    untrackedPitch: false,
    withoutGuid: false,
    withoutSpin: false
  },
  problemPitchInfo: {},
  problemPitchKeys: {
    broadcastVideo: "Broadcast",
    centerfieldVideo: "Legacy CF",
    missingCalibration: "No PC Calibration",
    opticalTracking: "Optical",
    pitchcastVideo: "Pitchcast",
    reducedConfidence: "Reduced Confidence",
    untrackedBip: "BIP",
    untrackedPitch: "Untracked",
    withoutGuid: "No Play ID",
    withoutSpin: "No Spin"
  },
  problemPitchKeyOrder: [
    "untrackedPitch",
    "withoutGuid",
    "reducedConfidence",
    "missingCalibration",
    "pitchcastVideo",
    "centerfieldVideo",
    "broadcastVideo",
    "opticalTracking",
    "withoutSpin"
  ],
  timingOffset: 0,
  keyframeTs: 3.2,
  plateTs: 0,
  stepSize: 0.5,
  applyCalibrationValues: {
    rangeStart: "",
    rangeEnd: "",
    list: "",
    calibrationMode: 1
  },
  sort: {
    col: "",
    asc: true
  },
  selectedActionItem: 1,
  isStrikeZone: false,
  currentRequests: {},
  cameraCalStrikeZone: false,
  loading: false,
  calibrationStatus: CalibrationStatus.IDLE,
  dragLine: null,
  autoSaveDate: null,
  hoverAndClick: true,
  doAutoSave: false,
  autoPaste: false,
  preload: false,
  preloadRange: 5,
  preloadVideos: [],
  showCancelModal: false,
  showConfirmationModal: false,
  drawSecondLine: true,
  confirmationModalTitle: "Confirm Changes",
  modalAction: null,
  trackLine: true,
  callCorrectness: {
    ACCEPTABLE: false,
    CATCHER_INFLUENCE: false,
    CORRECT: false,
    HIGH_BUFFER: false,
    INCORRECT: false,
    LOW_BUFFER: false,
    LOW_CATCH: false,
    UNKNOWN: false
  },
  callCorrectnessTotals: {},
  dirtyCamCalMarkers: {},
  researchImage: null,
  fieldOutlineTranslationAdjustment: { horizontal: 0, vertical: 0 },
  plateMarkerIndexes: [90, 91, 92, 93, 80, 81, 82, 83, 4, 5],
  moundMarkerIndexes: [70, 71, 72, 20, 21],
  secondBaseMarkerIndexes: [13], // Only the back tip of 2B is used
  cameraCalMarkersSequence: [91, 90, 92, 93, 80, 81, 83, 82, 4, 5, 2, 3, 13, 11, 12, 70, 71, 72, 20, 21, 30],
  cameraCalMarkers: observable.map({
    90: {
      id: 90,
      name: "Left BB FR",
      visible: false,
      key: "battersBoxLeft",
      keyIndex: 0,
      desc: "Left Batters Box Front Right",
      img: "assets/cameraCal/bbl-front-right.png",
      default: [
        [217.8203125, 478.4453125],
        [215.7109375, 412.1640625],
        [208.2734375, 422.65625],
        [210.328125, 489.9375]
      ]
    },
    91: {
      id: 91,
      name: "Left BB FL",
      visible: false,
      key: "battersBoxLeft",
      keyIndex: 1,
      desc: "Left Batters Box Front Left",
      img: "assets/cameraCal/bbl-front-left.png",
      default: [
        [217.8203125, 478.4453125],
        [215.7109375, 412.1640625],
        [208.2734375, 422.65625],
        [210.328125, 489.9375]
      ]
    },
    92: {
      id: 92,
      name: "Left BB BL",
      visible: false,
      key: "battersBoxLeft",
      keyIndex: 2,
      desc: "Left Batters Box Back Left",
      img: "assets/cameraCal/bbl-back-left.png",
      default: [
        [217.8203125, 478.4453125],
        [215.7109375, 412.1640625],
        [208.2734375, 422.65625],
        [210.328125, 489.9375]
      ]
    },
    93: {
      id: 93,
      name: "Left BB BR",
      visible: false,
      key: "battersBoxLeft",
      keyIndex: 3,
      desc: "Left Batters Box Back Right",
      img: "assets/cameraCal/bbl-back-right.png",
      default: [
        [217.8203125, 478.4453125],
        [215.7109375, 412.1640625],
        [208.2734375, 422.65625],
        [210.328125, 489.9375]
      ]
    },
    80: {
      id: 80,
      name: "Right BB FL",
      visible: false,
      key: "battersBoxRight",
      keyIndex: 0,
      desc: "Right Batters Box Front Left",
      img: "assets/cameraCal/bbr-front-left.png",
      default: [
        [218.9296875, 532.453125],
        [220.9296875, 600.734375],
        [213.546875, 608.0625],
        [211.4375, 542.9453125]
      ]
    },
    81: {
      id: 81,
      name: "Right BB FR",
      visible: false,
      key: "battersBoxRight",
      keyIndex: 1,
      desc: "Right Batters Box Front Right",
      img: "assets/cameraCal/bbr-front-right.png",
      default: [
        [218.9296875, 532.453125],
        [220.9296875, 600.734375],
        [213.546875, 608.0625],
        [211.4375, 542.9453125]
      ]
    },
    82: {
      id: 82,
      name: "Right BB BR",
      visible: false,
      key: "battersBoxRight",
      keyIndex: 2,
      desc: "Right Batters Box Back Right",
      img: "assets/cameraCal/bbr-back-right.png",
      default: [
        [218.9296875, 532.453125],
        [220.9296875, 600.734375],
        [213.546875, 608.0625],
        [211.4375, 542.9453125]
      ]
    },
    83: {
      id: 83,
      name: "Right BB BL",
      visible: false,
      key: "battersBoxRight",
      keyIndex: 3,
      desc: "Right Batters Box Back Left",
      img: "assets/cameraCal/bbr-back-left.png",
      default: [
        [218.9296875, 532.453125],
        [220.9296875, 600.734375],
        [213.546875, 608.0625],
        [211.4375, 542.9453125]
      ]
    },
    2: {
      id: 2,
      name: "Left Foul Line",
      visible: false,
      key: "foulLineLeft",
      keyIndex: -1,
      desc: "Left Foul Line",
      img: "assets/cameraCal/foul-line-left.png",
      default: [217.8203125, 435.4765625]
    },
    3: {
      id: 3,
      name: "Right Foul Line",
      visible: false,
      key: "foulLineRight",
      keyIndex: -1,
      desc: "Right Foul Line",
      img: "assets/cameraCal/foul-line-right.png",
      default: [220.984375, 584.75]
    },
    4: {
      id: 4,
      name: "Left Plate Side",
      visible: false,
      key: "homeplateTipLeft",
      keyIndex: -1,
      desc: "Home Plate Left Tip",
      img: "assets/cameraCal/home-left.png",
      default: [216.8203125, 496.484375]
    },
    5: {
      id: 5,
      name: "Right Plate Side",
      visible: false,
      key: "homeplateTipRight",
      keyIndex: -1,
      desc: "Home Plate Right Tip",
      img: "assets/cameraCal/home-right.png",
      default: [217.765625, 523.796875]
    },
    6: {
      id: 6,
      name: "HP Circle Back",
      visible: false,
      key: "homeplateCircleBack",
      keyIndex: -1,
      desc: "Home Plate Circle Back",
      img: "assets/cameraCal/hp-circle-back.png",
      default: [195.671875, 526.9609375]
    },
    7: {
      id: 7,
      name: "HP Circle Left",
      visible: false,
      key: "homeplateCircleLeft",
      keyIndex: -1,
      desc: "Home Plate Circle Left",
      img: "assets/cameraCal/hp-circle-left.png",
      default: [209.3828125, 249.578125]
    },
    8: {
      id: 8,
      name: "HP Circle Right",
      visible: false,
      key: "homeplateCircleRight",
      keyIndex: -1,
      desc: "Home Plate Circle Right",
      img: "assets/cameraCal/hp-circle-right.png",
      default: [223.09375, 779.03125]
    },
    9: {
      id: 9,
      name: "HP Circle Front",
      visible: false,
      key: "homeplateCircleFront",
      keyIndex: -1,
      desc: "Home Plate Circle Front",
      img: "assets/cameraCal/hp-circle-front.png",
      default: [223.09375, 779.03125]
    },
    70: {
      id: 70,
      name: "Left Mound",
      visible: false,
      key: "moundCircle",
      keyIndex: 0,
      desc: "Left side of the Pitcher's Mound",
      img: "assets/cameraCal/mound-left.png",
      default: [
        [299.140625, 176.8046875],
        [323.3984375, 345.1015625],
        [311.6328125, 600.734375]
      ]
    },
    71: {
      id: 71,
      name: "Back Mound",
      visible: false,
      key: "moundCircle",
      keyIndex: 1,
      desc: "Back side of the Pitcher's Mound",
      img: "assets/cameraCal/mound-back.png",
      default: [
        [299.140625, 176.8046875],
        [323.3984375, 345.1015625],
        [311.6328125, 600.734375]
      ]
    },
    72: {
      id: 72,
      name: "Right Mound",
      visible: false,
      key: "moundCircle",
      keyIndex: 2,
      desc: "Right side of the Pitcher's Mound",
      img: "assets/cameraCal/mound-right.png",
      default: [
        [299.140625, 176.8046875],
        [323.3984375, 345.1015625],
        [311.6328125, 600.734375]
      ]
    },
    10: {
      id: 10,
      name: "Second Base Centroid",
      visible: false,
      key: "secondBaseCentroid",
      keyIndex: -1,
      desc: "Second Base Centroid",
      img: "assets/cameraCal/2nd-center.png",
      default: [446.796875, 191.625]
    },
    11: {
      id: 11,
      name: "Second Base Left",
      visible: false,
      key: "secondBaseLeftTip",
      keyIndex: -1,
      desc: "Second Base Left Tip",
      img: "assets/cameraCal/2nd-left.png",
      default: [447.90625, 171.53125]
    },
    12: {
      id: 12,
      name: "Second Base Right",
      visible: false,
      key: "secondBaseRightTip",
      keyIndex: -1,
      desc: "Second Base Right Tip",
      img: "assets/cameraCal/2nd-right.png",
      default: [448.90625, 213.828125]
    },
    13: {
      id: 13,
      name: "Second Base Back",
      visible: false,
      key: "secondBaseFront",
      keyIndex: -1,
      desc: "Second Base Back Tip",
      img: "assets/cameraCal/2nd-back.png",
      default: [446.796875, 191.625]
    },
    20: {
      id: 20,
      name: "Rubber Back Left",
      visible: false,
      key: "rubberBackLeft",
      keyIndex: -1,
      desc: "PitchingRubber Back Left",
      img: "assets/cameraCal/rubber-back-left.png",
      default: [291.6484375, 363.484375]
    },
    21: {
      id: 21,
      name: "Rubber Back Right",
      visible: false,
      key: "rubberBackRight",
      keyIndex: -1,
      desc: "Pitching Rubber Back Right",
      img: "assets/cameraCal/rubber-back-right.png",
      default: [294.8125, 403.5625]
    },
    22: {
      id: 22,
      name: "Rubber Front Left",
      visible: false,
      key: "rubberFrontLeft",
      keyIndex: -1,
      desc: "Pitching Rubber Front Left",
      img: "assets/cameraCal/rubber-front-left.png",
      default: [289.5390625, 363.484375]
    },
    23: {
      id: 23,
      name: "Rubber Front Right",
      visible: false,
      key: "rubberFrontRight",
      keyIndex: -1,
      desc: "Pitching Rubber Front Right",
      img: "assets/cameraCal/rubber-front-right.png",
      default: [290.59375, 404.6171875]
    },
    30: {
      id: 30,
      name: "Release Point",
      visible: false,
      key: "releasePoint",
      keyIndex: -1,
      desc: "Pitcher's release point",
      img: null,
      default: [173.09375, 480.6171875]
    }
  }),
  marker: {
    zoom: {
      height: 81,
      width: 61
    },
    normal: {
      height: 20,
      width: 15
    }
  },
  selectedCameraCal: -1,
  venueId: -1
};

export const calibrationFields = [
  "battersBoxLeft",
  "battersBoxRight",
  "foulLineLeft",
  "foulLineRight",
  "homeplateTipLeft",
  "homeplateTipRight",
  "moundCircle",
  "secondBaseFront",
  "secondBaseLeftTip",
  "secondBaseRightTip"
];

export const strikeZoneBoundaries = {
  szTopUpper: 4.333,
  szTopLower: 2.333,
  szBottomUpper: 2,
  szBottomLower: 1
};

export const szOobThreshold = {
  top: 0.05,
  bottom: 0.03
};

export const hotkeyMap = {
  nextPitch: "s",
  prevPitch: "a",
  stepBackward: "z",
  stepForward: "x",
  upHandler: "up",
  downHandler: "down",
  leftHandler: "left",
  rightHandler: "right",
  toggleZoom: "shift",
  toggleAutoPaste: "v",
  preloadHotkeyHandler: "c",
  toggleShowBoundaries: "t",
  toggleDoAutoSave: "g",
  toggleHoverAndClick: "h",
  toggleCameraCalStrikeZone: "j",
  topStrikeZoneUpHandler: "-",
  topStrikeZoneDownHandler: "[",
  bottomStrikeZoneUpHandler: "=",
  bottomStrikeZoneDownHandler: "]",
  plate: "f",
  keyframe: "d",
  prevMarker: "q",
  nextMarker: "w",
  playPauseHandler: "space",
  trackLineHandler: "/",
  decreaseStepSize: "e",
  increaseStepSize: "r",
  tabHandler: "`",
  dirtyHandler: "b",
  saveCurrentPitchCalibration: "ctrl+shift+s"
};

export const rmseMarkerMap = {
  "batters_box_left-0": 90,
  "batters_box_left-1": 91,
  "batters_box_left-2": 92,
  "batters_box_left-3": 93,
  "batters_box_right-0": 80,
  "batters_box_right-1": 81,
  "batters_box_right-2": 82,
  "batters_box_right-3": 83,
  foul_line_left: 2,
  foul_line_right: 3,
  homeplate_tip_left: 4,
  homeplate_tip_right: 5,
  "mound_circle-0": 70,
  "mound_circle-1": 71,
  "mound_circle-2": 72,
  second_base_centroid: 10,
  second_base_left_tip: 11,
  second_base_right_tip: 12,
  second_base_front: 13,
  rubber_back_left: 20,
  rubber_back_right: 21,
  release_point: 30
};
