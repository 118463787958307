export const callCorrectnessDisplayText = {
  ACCEPTABLE: "Acceptable",
  CATCHER_INFLUENCE: "Catcher Influence",
  CORRECT: "Correct",
  HIGH_BUFFER: "High Buffer",
  INCORRECT: "Incorrect",
  LOW_BUFFER: "Low Buffer",
  LOW_CATCH: "Low Catch"
};

export const hotkeyMap = {
  nextPitch: "s",
  playPauseHandler: "space",
  prevPitch: "a",
  stepBackward: "z",
  stepForward: "x"
};

export const queryParams = {
  playId: "playId",
  pitchNumber: "pitchNumber"
};

export const plateCorners = {
  firstBaseFront: {
    x: 0.70833,
    y: 1.41667,
    z: 0
  },
  firstBaseBack: {
    x: 0.70833,
    y: 0.70833,
    z: 0
  },
  origin: {
    x: 0,
    y: 0,
    z: 0
  },
  thirdBaseBack: {
    x: -0.70833,
    y: 0.70833,
    z: 0
  },
  thirdBaseFront: {
    x: -0.70833,
    y: 1.41667,
    z: 0
  }
};

export const video = {
  videoCenter: [270, 480],
  szVideoCenter: [540, 960]
};
