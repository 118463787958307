import React from "react";
import styled from "styled-components";
import ReactAnimationFrame from "react-animation-frame";
import { inject } from "mobx-react";
import { CanvasUtilities } from "../../../../utilities/CanvasUtilities";
import { GameViewerBatterCanvasConstants as canvasConstants } from "./GameViewerBatterCanvasConstants";

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const {
  PIXELS_PER_INCH,
  PITCH_CIRCLE_RADIUS,
  PLATE_CF,
  PLATE_CF_BUFFER_OUTER,
  PLATE_CF_BUFFER_INNER,
  PLATE_HF_BUFFER_BOTTOM_INNER,
  PLATE_HF_BUFFER_BOTTOM_OUTER,
  PLATE_HF_BUFFER_TOP_INNER,
  PLATE_HF_BUFFER_TOP_OUTER,
  PLATE_FRONT_Y,
  PLATE_HH,
  PLATE_HH_BUFFER,
  PLATE_HH_BUFFER_INNER,
  PLATE_HH_BUFFER_MID,
  PLATE_HH_BUFFER_OUTER,
  PLATE_Y,
  PLATE_1B_EDGE_X,
  PLATE_3B_EDGE_X,
  STRIKE_ZONE_BOX,
  STRIKE_ZONE_1B_BUFFER,
  STRIKE_ZONE_3B_BUFFER,
  SZ_EDGE_TOP,
  SZ_EDGE_BOTTOM,
  SZ_MID_X
} = canvasConstants;

class GameViewerBatterCanvas extends React.Component {
  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");

    setTimeout(this.draw(), 2000);
  }

  constructor(props) {
    super(props);

    this.onCanvasClick = this.onCanvasClick.bind(this);
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
  }

  draw() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { cartoonAngle } = gameViewerStore;
    switch (cartoonAngle) {
      case "centerfield":
        this.drawCenterfield();
        break;
      case "high_home":
        this.drawHighHome();
        break;
      case "high_first":
        this.drawHighFirst();
        break;
      default:
        break;
    }
  }

  calculateStrikeZoneBox() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchList, selectedPitch } = gameViewerStore;
    let width = 17;
    if (selectedPitch && selectedPitch.szWidth) {
      width = selectedPitch.szWidth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szWidth) {
      width = pitchList[0].szWidth;
    }
    return [
      [SZ_EDGE_TOP + 0.5, SZ_MID_X - (1 / 2) * width * PIXELS_PER_INCH + 0.5],
      [SZ_EDGE_BOTTOM - 0.5, SZ_MID_X - (1 / 2) * width * PIXELS_PER_INCH + 0.5],
      [SZ_EDGE_BOTTOM - 0.5, SZ_MID_X + (1 / 2) * width * PIXELS_PER_INCH - 0.5],
      [SZ_EDGE_TOP + 0.5, SZ_MID_X + (1 / 2) * width * PIXELS_PER_INCH - 0.5]
    ];
  }

  drawBackgroundCF() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const {
      bufferConfiguration,
      selectedPitch,
      game: { absMode }
    } = gameViewerStore;
    if (bufferConfiguration && (!absMode || absMode === "noABS")) {
      if (bufferConfiguration === "V2" && selectedPitch) {
        this.drawCFRectangularBuffers(selectedPitch);
      } else if (bufferConfiguration === "V1") {
        for (let idx = 0; idx < STRIKE_ZONE_1B_BUFFER.length - 1; idx++) {
          CanvasUtilities.drawLinePointToPoint(
            this.ctx,
            STRIKE_ZONE_1B_BUFFER[idx],
            STRIKE_ZONE_1B_BUFFER[idx + 1],
            CanvasUtilities.COLORS.RED,
            1,
            3
          );
        }
        for (let idx = 0; idx < STRIKE_ZONE_3B_BUFFER.length - 1; idx++) {
          CanvasUtilities.drawLinePointToPoint(
            this.ctx,
            STRIKE_ZONE_3B_BUFFER[idx],
            STRIKE_ZONE_3B_BUFFER[idx + 1],
            CanvasUtilities.COLORS.RED,
            1,
            3
          );
        }
        if (selectedPitch) {
          const verticalPixelsPerInch = this.getVerticalPixelsPerInch(selectedPitch);
          this.drawLowBuffer(selectedPitch, verticalPixelsPerInch);
        }
      }
    }
    let strikeZoneBox = this.calculateStrikeZoneBox();
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...PLATE_CF, CanvasUtilities.COLORS.BLACK);
    for (let idx = 0; idx < strikeZoneBox.length; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        strikeZoneBox[idx],
        strikeZoneBox[(idx + 1) % strikeZoneBox.length],
        CanvasUtilities.COLORS.BLACK
      );
    }
  }

  drawBackgroundHF() {
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...PLATE_CF, CanvasUtilities.COLORS.BLACK);
    let strikeZoneBox = this.calculateStrikeZoneBox();
    let depth = null;
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchList, selectedPitch } = gameViewerStore;
    if (selectedPitch && selectedPitch.szDepth) {
      depth = selectedPitch.szDepth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szDepth) {
      depth = pitchList[0].szDepth;
    }
    if (depth) {
      let change = depth - 8.5 !== 0 ? ((depth - 8.5) / 2) * PIXELS_PER_INCH : 0;
      strikeZoneBox = [
        [SZ_EDGE_TOP, SZ_MID_X - change],
        [SZ_EDGE_BOTTOM, SZ_MID_X - change]
      ];
    }
    for (let idx = 0; idx < strikeZoneBox.length; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        strikeZoneBox[idx],
        strikeZoneBox[(idx + 1) % strikeZoneBox.length],
        CanvasUtilities.COLORS.BLACK
      );
    }
  }

  drawBackgroundHH() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const {
      isMilbGame,
      game: { absMode }
    } = gameViewerStore;
    let depth = null;
    const { pitchList, selectedPitch } = gameViewerStore;
    if (selectedPitch && selectedPitch.szDepth) {
      depth = selectedPitch.szDepth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szDepth) {
      depth = pitchList[0].szDepth;
    }
    if (depth) {
      let change = depth !== 0 ? depth * PIXELS_PER_INCH : 0;
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        [PLATE_FRONT_Y - change, PLATE_1B_EDGE_X],
        [PLATE_FRONT_Y - change, PLATE_3B_EDGE_X],
        CanvasUtilities.COLORS.BLACK
      );
    } else {
      for (let idx = 0; idx < PLATE_HH.length; idx++) {
        CanvasUtilities.drawLinePointToPoint(
          this.ctx,
          PLATE_HH[idx],
          PLATE_HH[(idx + 1) % PLATE_HH.length],
          CanvasUtilities.COLORS.BLACK
        );
      }
    }
    if (!isMilbGame && !(absMode && absMode !== "noABS")) {
      this.drawHHBuffer(selectedPitch);
    } else {
      for (let idx = 0; idx < PLATE_HH.length - 1; idx++) {
        CanvasUtilities.drawLinePointToPoint(
          this.ctx,
          PLATE_HH[idx],
          PLATE_HH[(idx + 1) % PLATE_HH.length],
          CanvasUtilities.COLORS.LIGHT_RED,
          1,
          3
        );
      }
    }
  }

  drawCenterfield() {
    const { pitchListFiltered, selectedPitch } = this.props.rootStore.gameViewerStore;
    this.drawBackgroundCF();
    if (selectedPitch) {
      this.drawPitchCF(selectedPitch, true);
    } else {
      pitchListFiltered.forEach(p => this.drawPitchCF(p, false));
    }
  }

  drawCFRectangularBuffers(selectedPitch) {
    const { functions } = this.props.rootStore.gameViewerStore;
    if (!functions.isCalledPitch(selectedPitch)) {
      return;
    }
    let outerBuffer, innerBuffer;
    if (functions.isCalledStrike(selectedPitch)) {
      outerBuffer = PLATE_CF_BUFFER_OUTER;
      innerBuffer = STRIKE_ZONE_BOX;
    } else if (functions.isCalledBall(selectedPitch)) {
      outerBuffer = STRIKE_ZONE_BOX;
      innerBuffer = PLATE_CF_BUFFER_INNER;
    } else {
      return;
    }
    CanvasUtilities.fillPolygon(this.ctx, outerBuffer, CanvasUtilities.COLORS.MLB_BLUE, 0.4);
    CanvasUtilities.fillPolygon(this.ctx, innerBuffer, CanvasUtilities.COLORS.BACKGROUND_OFFWHITE, 1);
  }

  drawHighFirstBufferV2(selectedPitch) {
    const { functions } = this.props.rootStore.gameViewerStore;
    if (functions.isCalledStrike(selectedPitch)) {
      CanvasUtilities.fillPolygon(this.ctx, PLATE_HF_BUFFER_TOP_OUTER, CanvasUtilities.COLORS.MLB_BLUE, 0.4);
      CanvasUtilities.fillPolygon(this.ctx, PLATE_HF_BUFFER_BOTTOM_OUTER, CanvasUtilities.COLORS.MLB_BLUE, 0.4);
    } else if (functions.isCalledBall(selectedPitch)) {
      CanvasUtilities.fillPolygon(this.ctx, PLATE_HF_BUFFER_TOP_INNER, CanvasUtilities.COLORS.MLB_BLUE, 0.4);
      CanvasUtilities.fillPolygon(this.ctx, PLATE_HF_BUFFER_BOTTOM_INNER, CanvasUtilities.COLORS.MLB_BLUE, 0.4);
    }
  }

  drawHHBuffer(selectedPitch) {
    const { bufferConfiguration } = this.props.rootStore.gameViewerStore;
    if (bufferConfiguration === "V1") {
      this.drawHHBufferV1();
    } else {
      this.drawHHBufferV2(selectedPitch);
    }
  }

  drawHHBufferV1() {
    for (let idx = 0; idx < PLATE_HH_BUFFER.length - 1; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        PLATE_HH_BUFFER[idx],
        PLATE_HH_BUFFER[(idx + 1) % PLATE_HH_BUFFER.length],
        CanvasUtilities.COLORS.RED,
        1,
        3
      );
    }
  }

  drawHHBufferV2(selectedPitch) {
    const { functions } = this.props.rootStore.gameViewerStore;
    let outerBuffer, innerBuffer;
    if (functions.isCalledStrike(selectedPitch)) {
      outerBuffer = PLATE_HH_BUFFER_OUTER;
      innerBuffer = PLATE_HH_BUFFER_MID;
    } else if (functions.isCalledBall(selectedPitch)) {
      outerBuffer = PLATE_HH_BUFFER_MID;
      innerBuffer = PLATE_HH_BUFFER_INNER;
    } else {
      return;
    }
    CanvasUtilities.fillPolygon(this.ctx, outerBuffer, CanvasUtilities.COLORS.MLB_BLUE, 0.4);
    CanvasUtilities.fillPolygon(this.ctx, innerBuffer, CanvasUtilities.COLORS.BACKGROUND_OFFWHITE, 1);
  }

  drawHighFirst() {
    const {
      selectedPitch,
      isMilbGame,
      bufferConfiguration,
      game: { absMode }
    } = this.props.rootStore.gameViewerStore;
    const verticalPixelsPerInch = this.getVerticalPixelsPerInch(selectedPitch);
    this.drawPitchHF();
    if (!isMilbGame && !(absMode && absMode !== "noABS")) {
      if (bufferConfiguration === "V1") {
        this.drawLowBuffer(selectedPitch, verticalPixelsPerInch);
      } else if (bufferConfiguration === "V2") {
        this.drawHighFirstBufferV2(selectedPitch);
      }
    }
    this.drawBackgroundHF();
    this.drawPitchHF();
  }

  drawHighHome() {
    this.drawBackgroundHH();
    this.drawPitchHH();
  }

  drawLowBuffer(pitch, verticalPixelsPerInch) {
    const lowBufferPixels = verticalPixelsPerInch * 1.45;
    const lowBufferLine = this.getLowBufferLineCoordinates(lowBufferPixels);
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...lowBufferLine, CanvasUtilities.COLORS.RED, 1, 3);
  }

  getLowBufferLineCoordinates(lowBufferPixels) {
    let strikeZoneBox = this.calculateStrikeZoneBox();
    return [
      [strikeZoneBox[1][0] - lowBufferPixels, strikeZoneBox[1][1]],
      [strikeZoneBox[2][0] - lowBufferPixels, strikeZoneBox[2][1]]
    ];
  }

  drawPitchCF(pitch, selected) {
    const { functions, hoverPitches, pitchListFiltered } = this.props.rootStore.gameViewerStore;
    const coord = pitch.canvasPositions?.centerfield;
    if (!coord) {
      return;
    }
    const color = functions.getPitchColor(pitch);
    if (pitchListFiltered.length > 1) {
      for (let idx = 0; idx < hoverPitches.length; idx++) {
        if (hoverPitches[idx].playId === pitch.playId) {
          selected = true;
        }
      }
    }
    const fillColor = selected ? color : null;
    if (coord.row < 8) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, 8, coord.col, 8, color, fillColor, 90);
    } else if (coord.row > PLATE_Y) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, PLATE_Y, coord.col, 8, color, fillColor, -90);
    } else if (coord.col < 8) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, coord.row, 8, 8, color, fillColor, 0);
    } else if (coord.col > 227) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, coord.row, 227, 8, color, fillColor, 180);
    } else {
      CanvasUtilities.drawCircle(this.ctx, coord.row, coord.col, PITCH_CIRCLE_RADIUS, color, fillColor);
    }
  }

  drawPitchHF() {
    const { functions, selectedPitch } = this.props.rootStore.gameViewerStore;
    const coords = selectedPitch.canvasPositions?.highFirst;
    if (!coords || coords.length !== 2) {
      return;
    }
    const color = functions.getPitchColor(selectedPitch);
    CanvasUtilities.drawLinePointToPointRowCol(this.ctx, ...coords, color, PITCH_CIRCLE_RADIUS * 2);
  }

  drawPitchHH() {
    const { functions, selectedPitch } = this.props.rootStore.gameViewerStore;
    const coords = selectedPitch.canvasPositions?.highHome;
    if (!coords || coords.length !== 2) {
      return;
    }
    const color = functions.getPitchColor(selectedPitch);
    CanvasUtilities.drawLinePointToPointRowCol(this.ctx, ...coords, color, PITCH_CIRCLE_RADIUS * 2);
  }

  getVerticalPixelsPerInch(pitch) {
    let strikeZoneBox = this.calculateStrikeZoneBox();
    if (!pitch) {
      return (strikeZoneBox[1][0] - strikeZoneBox[0][0]) / 18;
    }
    const { szTop, szBottom } = pitch;
    const szHeightInInches = szTop && szBottom ? (szTop - szBottom) * 12 : 18;
    return (strikeZoneBox[1][0] - strikeZoneBox[0][0]) / szHeightInInches;
  }

  onAnimationFrame(time) {
    if (this.ctx) {
      this.clear();
      this.draw();
    }
  }

  onCanvasClick(event) {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { cartoonAngle, hoverPitches, selectedPitch } = gameViewerStore;
    if (cartoonAngle !== "centerfield") {
      return;
    }
    if (!hoverPitches.length) {
      return;
    }
    if (selectedPitch) {
      hoverPitches.forEach(hp => {
        if (hp.playId === selectedPitch.playId) {
          gameViewerStore.setSelectedPitch(null);
        }
      });
    } else if (hoverPitches.length === 1) {
      gameViewerStore.setSelectedPitch(hoverPitches[0], true);
    } else if (hoverPitches.length > 1) {
      gameViewerStore.setPitchListFilter(hoverPitches.map(hp => hp.pitchNumber).join(","));
    }
  }

  onCanvasMouseMove(event) {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchListFiltered } = gameViewerStore;
    const { x, y } = event.currentTarget.getBoundingClientRect();
    const { clientX, clientY } = event;
    let canvasPosition = {
      row: clientY - y,
      col: clientX - x
    };
    let hoverPitches = [];
    pitchListFiltered.forEach(p => {
      const distance = CanvasUtilities.distanceBetweenPoints(p.canvasPositions.centerfield, canvasPosition);
      if (distance > -1 && distance < PITCH_CIRCLE_RADIUS) {
        hoverPitches.push(p);
      }
    });
    gameViewerStore.setHoverPitches(hoverPitches);
  }

  render() {
    return (
      <Canvas
        {...this.props}
        innerRef={c => {
          this.canvas = c;
        }}
        {...{
          onClick: this.onCanvasClick,
          onMouseMove: this.onCanvasMouseMove
        }}
      />
    );
  }
}

export default inject("rootStore")(ReactAnimationFrame(GameViewerBatterCanvas));
